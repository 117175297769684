<template>
  <page-loader :loading="isLoading">
    <div v-if="invoice && reconciliation">
      <confirm-modal ref="confirmModal" />
      <create-or-update-infocard-modal ref="infoCardCreateModal" />
      <confirm-waste-collection-modal ref="confirmWasteCollectionModal" />
      <posting-edit-modal ref="postingEditModal" />
      <update-invoice-line-modal ref="UpdateInvoiceLineModal" />
      <add-invoice-line-modal ref="AddInvoiceLineModal" />

      <content-modal
        id="postingOverviewModal"
        body-class="p-0"
        size="lg"
        :title="T('Web.Generic.Confirm', 'Confirm')"
      >
        <posting-overview-modal-inner
          :postings="reconciliation.postings"
          :infocards="infoCards"
          :lines="invoice.invoiceLines"
          v-if="infoCards && invoice"
        />
        <template v-slot:modal-footer>
          <div class="w-100">
            <b-button
              v-if="!isLocked"
              class="float-right dark-btn"
              @click="handleSaveAndClose"
              :disabled="submitting"
            >
              {{
                T("Web.Generic.Invoices.Reconciliations.SaveAndFinish")
              }}</b-button
            >
            <b-button
              variant="secondary"
              class="float-right mr-1"
              @click="$bvModal.hide('postingOverviewModal')"
            >
              {{ T("Web.Generic.Cancel") }}
            </b-button>
          </div>
        </template>
      </content-modal>

      <div class="action-head">
        <div class="d-flex justify-content-start">
          <div>
            <b-form-select
              size="sm"
              v-model="reconciliation.locationScopeId"
              @change="(id) => (selectedLocationId = id)"
              :disabled="isLocked"
            >
              <b-form-select-option :value="null" disabled>{{
                T("Web.Generic.Locations.SelectLocation")
              }}</b-form-select-option>
              <b-form-select-option
                :value="location.id"
                v-for="location in locations"
                :key="location.id"
                >{{ location.name }}</b-form-select-option
              >
            </b-form-select>
          </div>
          <b-button
            v-if="!isLocked"
            class="ml-1"
            variant="flat-secondary"
            size="sm"
            @click.prevent="setLocation(selectedLocationId)"
            >{{ T("Web.Generic.Invoices.Reconciliations.ConfirmLocation") }}
            <feather-icon icon="MapPinIcon" />
          </b-button>
          <b-button
            v-if="!isLocked"
            size="sm"
            variant="flat-secondary"
            @click.prevent="openinfoCardCreateModal"
          >
            {{ T("Web.Generic.Infocards.CreateInfocard") }}
            <feather-icon icon="PlusIcon" />
          </b-button>
        </div>

        <div class="d-flex align-items-center justify-content-center">
          <b-form-checkbox
            :value="true"
            :unchecked-value="false"
            class="float-left"
            v-model="invoice.needsConfirmation"
            @change="updateInvoiceNeedsConfirmation"
          >
            {{ T("Web.Generic.Invoices.Reconciliations.MarkForConfirmation") }}
          </b-form-checkbox>
          <b-button
            :variant="showSourcePdf ? 'secondary' : 'outline-secondary'"
            size="sm"
            v-if="sourceFileIsPdf"
            @click="showSourcePdf = !showSourcePdf"
            class="ml-1"
          >
            {{ showSourcePdf ? "Skjul PDF-faktura" : "Vis PDF-faktura" }}
            <feather-icon icon="FileTextIcon" />
          </b-button>
          <b-button
            v-else-if="sourceFileIsXml"
            class="ml-1"
            :variant="showSourceXml ? 'secondary' : 'outline-secondary'"
            @click="showSourceXml = !showSourceXml"
            size="sm"
          >
            {{ showSourceXml ? "Skjul Faktura" : "Vis Faktura" }}
            <feather-icon icon="FileTextIcon" />
          </b-button>
        </div>

        <div class="d-flex justify-content-end align-items-center">
          <div>
            <b-form-checkbox switch class="mr-1 mb-0" v-model="newLayoutMode">{{
              T("Web.Generic.Invoices.Reconciliations.UseNewLayout")
            }}</b-form-checkbox>
          </div>
          <b-button
            v-if="!isLocked"
            class="mr-1"
            variant="outline-secondary"
            size="sm"
            @click.prevent="refreshSuggestions"
            :disabled="!locationConfirmed"
          >
            {{ T("Web.Generic.Invoices.Reconciliations.RefreshSuggestions") }}
            <feather-icon icon="RefreshCwIcon" />
          </b-button>
          <b-button
            v-if="!isLocked"
            size="sm"
            class="btn-black mr-1"
            @click.prevent="handleSaveClicked"
          >
            {{ T("Web.Generic.Save") }} <feather-icon icon="SaveIcon" />
          </b-button>
          <b-button
            v-if="!isLocked"
            size="sm"
            variant="primary"
            @click="$bvModal.show('postingOverviewModal')"
            :disabled="!locationConfirmed"
            >{{ T("Web.Generic.Invoices.Reconciliations.SaveAndFinish") }}
            <feather-icon icon="CheckIcon"
          /></b-button>
          <b-button
            size="sm"
            variant="outline-secondary"
            @click="reopenReconciliation"
            v-if="isLocked"
            class="mr-1"
          >
            {{ T("Web.Generic.Edit") }} <feather-icon icon="Edit3Icon"
          /></b-button>
          <b-button
            v-if="isLocked"
            size="sm"
            variant="secondary"
            @click="$emit('go-back')"
            >{{ T("Web.Generic.Back") }} <feather-icon icon="CornerUpLeftIcon"
          /></b-button>
        </div>
      </div>
      <div class="invoice-head">
        <div>
          <h4>{{ T("Web.Generic.Invoices.Sender", "Sender") }}</h4>
          <p class="card-text mb-25">{{ invoice.supplier.name }}</p>
          <p class="card-text mb-25">
            {{ invoice.supplier.address }}
          </p>
          <p class="card-text mb-25">
            {{ invoice.supplier.zip }}
            {{ invoice.supplier.city }}
          </p>
          <p class="card-text">{{ invoice.supplier.cvr }}</p>
          <template v-if="invoice.note && invoice.note != ''">
            <br />
            <p class="font-weight-bold mb-0">
              {{ T("Web.Generic.Invoices.InvoiceNote", "Note") }}
            </p>
            <p class="card-text mb-25">{{ invoice.note }}</p>
          </template>
        </div>
        <div>
          <h4>{{ T("Web.Generic.Invoices.Reciver", "Receiver") }}</h4>
          <p class="card-text mb-25">{{ invoice.customer.name }}</p>
          <p class="card-text mb-25">
            {{ invoice.customer.address }}
          </p>
          <p class="card-text mb-25">
            {{ invoice.customer.zip }} {{ invoice.customer.city }}
          </p>
          <p class="card-text mb-25">{{ invoice.customer.cvr }}</p>
          <template
            v-if="
              invoice.deliveryLocation &&
              invoice.deliveryLocation.address != ' '
            "
          >
            <br />
            <p class="font-weight-bold">
              {{ T("Web.Generic.Invoices.DeliveryAddress") }}
            </p>
            <p class="card-text mb-25">
              {{ invoice.deliveryLocation.name }}
            </p>
            <p class="card-text mb-25">
              {{ invoice.deliveryLocation.address }}
            </p>
            <p class="card-text mb-25">
              {{ invoice.deliveryLocation.zip }}
              {{ invoice.deliveryLocation.city }}
            </p>
          </template>
          <template v-if="invoice.orderReferenceId">
            <br />

            <p class="font-weight-bold">
              {{ T("Web.Generic.Invoices.CustomerOrderNumber") }}
            </p>
            <p class="card-text mb-25">
              {{ invoice.orderReferenceId }}
            </p>
          </template>
        </div>
        <div>
          <h1 class="document-title">
            {{ getDocumentTypeDisplayText(invoice.documentType) }}
          </h1>
          <h2>#{{ invoice.invoiceNumber }}</h2>
          <h3>{{ format_date(invoice.issueDate) }}</h3>
        </div>
      </div>
      <div class="table-con">
        <div class="table-overlay" v-if="!locationConfirmed">
          <div class="overlay-content">
            <p>
              {{
                T(
                  "Web.Generic.Invoices.Reconciliations.ConfirmLocationToContinue"
                )
              }}
            </p>
          </div>
        </div>
        <div class="content-pane">
          <template v-if="sourceFileIsPdf">
            <div class="pdf-preview" v-if="showSourcePdf">
              <iframe
                class="h-100 w-100"
                :src="pdfUrl"
                frameborder="0"
              ></iframe>
            </div>
          </template>
          <template v-if="sourceFileIsXml">
            <div class="pdf-preview" v-if="showSourceXml">
              <div class="h-100 w-100">
                <iframe
                  class="h-100 w-100"
                  :src="`/economics/invoices/${reconciliationId}/xml/preview`"
                  frameborder="0"
                />
              </div>
            </div>
          </template>
          <div class="reconciliation-table">
            <b-overlay :show="isUpdatingSystemSuggestions || submitting">
              <template #overlay>
                <div class="text-center">
                  <b-spinner />
                  <p class="mt-1" v-if="isUpdatingSystemSuggestions">
                    {{
                      T(
                        "Web.Generic.Invoices.Reconciliations.GeneratingSystemSuggestions"
                      )
                    }}
                  </p>
                </div>
              </template>
              <b-table
                small
                :items="invoice.invoiceLines"
                :fields="tableFields"
                :tbody-tr-class="rowClass"
              >
                <template #cell(selectCheck)="data">
                  <b-form-checkbox
                    v-if="!isLocked"
                    :value="data.item.id"
                    v-model="selectedLines"
                    :name="`line_check_${data.item.id}`"
                  />
                </template>
                <template #cell(date)="data">
                  {{ format_date(data.value) }}
                </template>
                <template #cell(text)="data">
                  {{ data.value }}
                  <span v-if="data.item.description"
                    ><br />
                    <small>{{ data.item.description }}</small></span
                  >
                </template>
                <template #cell(amount)="data">
                  {{ data.value | toCurrency }}
                </template>
                <template #cell(priceAmount)="data">
                  {{ data.value | toCurrency }}
                </template>
                <template #cell(priceTotal)="data">
                  {{ data.value | toCurrency }}
                </template>
                <template #cell(postingUnit)="data">
                  <span class="font-weight-bold" v-if="isLocked">
                    {{ getLinePostingUnitText(data.item.id) }}
                  </span>
                  <b-form-select
                    v-else-if="!lineHasEmptyPosting(data.item.id)"
                    size="sm"
                    :options="unitTypeOptions"
                    :value="getLinePostingUnit(data.item.id)"
                    :ref="`${data.item.id}_postingUnit`"
                    @change="
                      (unit) => updatePostingUnitForLine(data.item.id, unit)
                    "
                    :disabled="!locationConfirmed"
                  />
                </template>
                <template #cell(posting)="data">
                  <span class="font-weight-bold" v-if="isLocked">
                    {{ getLinePostingCategoryText(data.item.id) }}
                  </span>
                  <b-form-select
                    v-else-if="!lineHasEmptyPosting(data.item.id)"
                    size="sm"
                    id="type-input"
                    :options="postingTypeOptions"
                    :disabled="!locationConfirmed"
                    :value="getLinePostingCategory(data.item.id)"
                    @change="
                      (category) =>
                        updatePostingCategoryForLine(data.item.id, category)
                    "
                    :ref="`${data.item.id}_postingCategory`"
                  />
                </template>
                <template #cell(infocard)="data">
                  <span class="font-weight-bold" v-if="isLocked">
                    {{ getLinePostingInfocardText(data.item.id) }}
                  </span>
                  <b-form-select
                    v-else-if="!lineHasEmptyPosting(data.item.id)"
                    size="sm"
                    id="type-input"
                    :options="infocardsOptions"
                    :disabled="!locationConfirmed"
                    :value="getLinePostingInfocard(data.item.id)"
                    @change="
                      (infocard) =>
                        updatePostingInfocardForLine(data.item.id, infocard)
                    "
                    :ref="`${data.item.id}_postingInfocard`"
                  />
                </template>
                <template #cell(lineActions)="data">
                  <div class="d-flex justify-content-end align-items-center">
                    <feather-icon
                      v-if="linePostingIsSystemGenerated(data.item.id)"
                      class="text-primary mr-1"
                      icon="FlagIcon"
                      size="16"
                      v-b-tooltip.hover.top="
                        T('Web.Generic.Postings.SystemSuggested')
                      "
                    />
                    <b-dropdown
                      size="sm"
                      variant="flat-secondary"
                      no-caret
                      v-if="!isLocked"
                    >
                      <template #button-content>
                        <feather-icon icon="MoreVerticalIcon" />
                      </template>
                      <b-dropdown-item
                        v-if="lineHasPosting(data.item.id) && !isLocked"
                        @click="
                          openEditPostingModal(getLinePosting(data.item.id))
                        "
                      >
                        <div
                          class="d-flex justify-content-between align-items-center"
                        >
                          <span>
                            {{
                              T(
                                "Web.Reconciliations.EditPosting",
                                "Edit posting"
                              )
                            }}
                          </span>

                          <feather-icon
                            icon="Edit3Icon"
                            class="ml-2"
                            size="16"
                          />
                        </div>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="lineHasPosting(data.item.id) && !isLocked"
                        @click="handleRemovePosting(data.item.id)"
                      >
                        <div
                          class="d-flex justify-content-between align-items-center text-danger"
                        >
                          <span>
                            {{
                              T(
                                "Web.Reconciliations.RemovePosting",
                                "Remove posting"
                              )
                            }}
                          </span>
                          <feather-icon
                            icon="DeleteIcon"
                            class="ml-2"
                            size="16"
                          />
                        </div>
                      </b-dropdown-item>
                      <b-dropdown-divider
                        v-if="lineHasPosting(data.item.id) && !isLocked"
                      />
                      <b-dropdown-item
                        v-if="!lineHasPosting(data.item.id)"
                        @click="openEditInvoiceLine(data.item)"
                      >
                        <div
                          class="d-flex justify-content-between align-items-center"
                        >
                          <span>
                            {{
                              T(
                                "Web.Reconciliations.EditInvoiceLine",
                                "Edit Invoice line"
                              )
                            }}
                          </span>

                          <feather-icon
                            icon="Edit3Icon"
                            class="ml-2"
                            size="16"
                          />
                        </div>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="!isLocked"
                        @click="removeInvoiceLine(data.item.id)"
                      >
                        <div
                          class="d-flex justify-content-between align-items-center text-danger"
                        >
                          <span>
                            {{
                              T(
                                "Web.Reconciliations.RemoveInvoiceLine",
                                "Remove invoice line"
                              )
                            }}
                          </span>
                          <feather-icon
                            icon="DeleteIcon"
                            class="ml-2"
                            size="16"
                          />
                        </div>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </template>
                <template #cell(postingResults)="data">
                  <div class="d-flex align-items-center">
                    <div>
                      <template v-if="lineHasPosting(data.item.id)">
                        <small>
                          {{
                            getLinePosting(data.item.id).postingQuantity
                              | toCurrency
                          }}
                          {{ getLinePostingUnitText(data.item.id) }}
                        </small>
                      </template>
                      <template v-if="lineHasSecondaryPosting(data.item.id)">
                        <br />
                        <small>
                          {{
                            getLinePosting(data.item.id)
                              .postingSecondaryQuantity | toCurrency
                          }}
                          {{ getLinePostingSecondaryUnitText(data.item.id) }}
                        </small>
                      </template>
                    </div>
                    <!--
                    <div v-if="lineHasPosting(data.item.id)">
                      <feather-icon
                        style="cursor: pointer"
                        class="text-primary ml-1"
                        icon="EditIcon"
                        v-if="
                          !isLocked &&
                          getLinePosting(data.item.id).postingCategory ==
                            'WasteCollection'
                        "
                        @click="editConfirmWasteCollection(data.item.id)"
                      />
                    </div>
                    -->
                  </div>
                </template>
              </b-table>
            </b-overlay>
          </div>
        </div>
        <div class="footer-actions float-right p-1">
          <b-button v-if="!isLocked" @click="openAddInvoiceLineModal" variant="flat-secondary" size="sm">
            {{T("Web.Reconciliations.AddInvoiceLine", "Add invoice line")}}

            <feather-icon icon="PlusIcon" />
          </b-button>
        </div>
      </div>
    </div>
  </page-loader>
</template>
<style lang="scss" scoped>
.content-pane {
  display: flex;
  .pdf-preview {
    width: 35%;
    min-height: calc(100vh - 315px);
    padding: 0px 10px 0px 10px;
    background: #f6f6f6;
    z-index: 99999;
  }
  .reconciliation-table {
    flex: 1;
  }
}
</style>
<script>
import { mapGetters, mapActions } from "vuex";
import {
  BTable,
  BFormSelect,
  BFormSelectOption,
  VBTooltip,
  BButton,
  BFormCheckbox,
  BOverlay,
  BSpinner,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
} from "bootstrap-vue";
import PageLoader from "@/app/components/layout/PageLoader.vue";
import ReconciliationService from "@/services/economics/reconciliation.service";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import CreateOrUpdateInfocardModal from "@/app/views/background-data/infocards/components/CreateOrUpdateInfocardModal.vue";
import ContentModal from "@/app/common/modals/ContentModal.vue";
import PostingOverviewModalInner from "./components/PostingOverviewModalInner.vue";
import ConfirmWasteCollectionModal from "./components/modals/ConfirmWasteCollectionModal.vue";
import Config from "@/configs";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import XmlInvoicePreview from "./components/XmlInvoicePreview.vue";
import PostingEditModal from "./components/PostingEditModal.vue";
import ResourceService from "@/services/base/resource.service";
import UpdateInvoiceLineModal from "./components/modals/UpdateInvoiceLineModal.vue";
import AddInvoiceLineModal from './components/modals/AddInvoiceLineModal.vue';

export default {
  props: {
    reconciliationId: {
      required: true,
    },
  },
  components: {
    BTable,
    BFormSelect,
    BFormSelectOption,
    BFormCheckbox,
    PageLoader,
    VBTooltip,
    BButton,
    ConfirmModal,
    BOverlay,
    BSpinner,
    CreateOrUpdateInfocardModal,
    ContentModal,
    PostingOverviewModalInner,
    ConfirmWasteCollectionModal,
    FeatherIcon,
    XmlInvoicePreview,
    PostingEditModal,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    UpdateInvoiceLineModal,
    AddInvoiceLineModal,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      submitting: false,
      isLoading: true,
      isUpdatingSystemSuggestions: false,
      showSourcePdf: false,
      showSourceXml: false,

      postingTypeOptions: [
        {
          value: "Unknown",
          text: this.T("Web.Generic.Choose"),
          disabled: true,
        },
        {
          value: "WasteCollection",
          text: this.T("Web.Generic.PostingCategories.WasteCollection"),
        },
        {
          value: "Rent",
          text: this.T("Web.Generic.PostingCategories.Rent"),
        },
        {
          value: "WrongSorting",
          text: this.T("Web.Generic.PostingCategories.WrongSorting"),
        },
        {
          value: "Charge",
          text: this.T("Web.Generic.PostingCategories.Charge"),
        },
        {
          value: "Income",
          text: this.T("Web.Generic.PostingCategories.Income"),
        },

        {
          value: "FutileDriving",
          text: this.T("Web.Generic.PostingCategories.FutileDriving"),
        },
        {
          value: "DeliveryOrRepatriation",
          text: this.T("Web.Generic.PostingCategories.DeliveryOrRepatriation"),
        },
        {
          value: "Exchange",
          text: this.T("Web.Generic.PostingCategories.Exchange"),
        },
        {
          label: this.T("Web.Generic.PostingCategories.Purchase", "Purchase"),
          options: [
            {
              value: "GoodsPurchase",
              text: this.T("Web.Generic.PostingCategories.GoodsPurchase"),
            },
            {
              value: "RepairPurchase",
              text: this.T("Web.Generic.PostingCategories.RepairPurchase"),
            },
            {
              value: "SuppliesPurchase",
              text: this.T("Web.Generic.PostingCategories.SuppliesPurchase"),
            },
            {
              value: "ContainerWash",
              text: this.T("Web.Generic.PostingCategories.ContainerWash"),
            },
          ],
        },
        {
          label: this.T("Web.Generic.PostingCategories.Fee", "Fee"),
          options: [
            {
              value: "RegistrationFee",
              text: this.T("Web.Generic.PostingCategories.RegistrationFee"),
            },
            {
              value: "OilSurchargeFee",
              text: this.T("Web.Generic.PostingCategories.OilSurchargeFee"),
            },
            {
              value: "OtherFees",
              text: this.T("Web.Generic.PostingCategories.OtherFees"),
            },
          ],
        },
      ],

      unitTypeOptions: [
        {
          value: "Unknown",
          text: this.T("Web.Generic.Choose"),
          disabled: true,
        },
        {
          value: "Pcs",
          text: this.T("Web.Generic.Units.Pcs"),
        },
        {
          value: "Tonnage",
          text: this.T("Web.Generic.Units.Tonnage"),
        },
        {
          value: "Kg",
          text: this.T("Web.Generic.Units.Kg"),
        },
        {
          value: "Hours",
          text: this.T("Web.Generic.Units.Hours"),
        },
        {
          value: "Cubic",
          text: this.T("Web.Generic.Units.Cubic"),
        },
      ],

      selectedLocationId: null,
      selectedLines: [],
    };
  },
  async created() {
    await this.initReconciliation();
  },
  computed: {
    ...mapGetters({
      getInvoice: "economicsInvoices/byId",
      getReconciliation: "economicsReconciliation/byId",
      locations: "locations/list",
      infoCards: "infoCards/list",
    }),
    sourceFileIsPdf() {
      return this.invoice.importFileName.toLowerCase().includes(".pdf");
    },
    sourceFileIsXml() {
      return this.invoice.importFileName.toLowerCase().endsWith(".xml");
    },
    pdfUrl() {
      return `${Config.api.hostname}/economics/invoices/${this.invoice.id}/source-file`;
    },
    newLayoutMode: {
      get() {
        return this.getUserSetting("reconciliation.layoutMode", false);
      },
      async set(value) {
        await this.setUserSettingAsync("reconciliation.layoutMode", value);
      },
    },
    isLocked() {
      return this.reconciliation?.status != "Draft";
    },
    invoice() {
      return this.getInvoice(this.reconciliationId);
    },
    reconciliation() {
      return this.getReconciliation(this.reconciliationId);
    },
    locationScope() {
      return this.reconciliation.locationScopeId;
    },
    tableFields() {
      return [
        {
          key: "selectCheck",
          label: "",
          sortable: false,
        },
        {
          key: "date",
          label: this.T("Web.Generic.Invoices.LineDate", "Date"),
          sortable: true,
        },
        {
          key: "text",
          label: this.T("Web.Generic.Invoices.LineDescription", "Description"),
          sortable: true,
        },
        {
          key: "amount",
          label: this.T("Web.Generic.Invoices.LineAmount", "Amount"),
          sortable: true,
        },
        {
          key: "unit",
          label: this.T("Web.Generic.Invoices.LineUnit", "Unit"),
          sortable: true,
        },
        {
          key: "priceAmount",
          label: this.T("Web.Generic.Invoices.LineUnitPrice", "Unit price"),
          sortable: true,
        },
        {
          key: "priceTotal",
          label: this.T("Web.Generic.Invoices.LineTotal", "Total price"),
          sortable: true,
        },
        {
          key: "infocard",
          label: this.T("Web.Generic.Infocards.InfocardName"),
        },
        {
          key: "postingUnit",
          label: this.T(
            "Web.Generic.Invoices.Reconciliations.PostingUnit",
            "Posting unit"
          ),
        },
        {
          key: "posting",
          label: this.T(
            "Web.Generic.Invoices.Reconciliations.PostingType",
            "Posting type"
          ),
        },
        {
          key: "postingResults",
          label: "",
        },

        {
          key: "lineActions",
          label: "",
        },
      ];
    },
    locationConfirmed() {
      return this.reconciliation.locationConfirmed;
    },
    accountInfocards() {
      return this.infoCards
        .filter((i) => i.type == "AccountInfoCard")
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    containerInfocards() {
      return this.infoCards
        .filter((i) => i.type == "ContainerInfoCard")
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    infocardsOptions() {
      return [
        {
          text: this.T("Web.Generic.Choose"),
          value: null,
        },
        {
          label: this.T("Web.Generic.Infocards.Types.Container") + "s",
          options: this.containerInfocards.map((c) => {
            return {
              value: c.id,
              text:
                c.internalGroupId != "" && c.internalGroupId != null
                  ? `${c.name} (${c.internalGroupId})`
                  : `${c.name} (${c.refId})`,
            };
          }),
        },
        {
          label: this.T("Web.Generic.Infocards.Types.Account") + "s",
          options: this.accountInfocards.map((c) => {
            return {
              value: c.id,
              text:
                c.internalGroupId != "" && c.internalGroupId != null
                  ? `${c.name} (${c.internalGroupId})`
                  : `${c.name} (${c.refId})`,
            };
          }),
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      fetchInvoice: "economicsInvoices/fetchSingle",
      fetchReconciliation: "economicsReconciliation/fetchSingle",
      fetchInfoCards: "infoCards/fetchList",
      deletePosting: "economicsReconciliation/deletePosting",
      getInfoCardPostingDraft:
        "economicsReconciliation/getInfoCardPostingDraft",
      createInfoCardPosting: "economicsReconciliation/createInfoCardPosting",
      updatePosting: "economicsReconciliation/updateInfoCardPosting",
      updateInvoiceNeedsConfirmationAction:
        "economicsInvoices/updateInvoiceNeedsConfirmation",
      refreshReconciliation: "economicsReconciliation/refreshReconciliation",
      reopenReconciliationAction: "invoiceImports/reopenReconciliation",
    }),
    async openEditInvoiceLine(line) {
      let modal = this.$refs.UpdateInvoiceLineModal.open(this.invoice.id, line);
    },
    async openAddInvoiceLineModal()
    {
      let result = await this.$refs.AddInvoiceLineModal.open(this.invoice.id);
      if(result == false) return;

      console.log(result);

      this.invoice.invoiceLines.push(result);
    },

    async removeInvoiceLine(lineId) {
      if (!(await this.$refs.confirmModal.confirm())) return;
      const invoiceService = new ResourceService(
        `economics/invoices/${this.invoice.id}/lines`
      );
      await invoiceService.delete(lineId);
      let indexToRemove = this.invoice.invoiceLines.indexOf(
        this.invoice.invoiceLines.find((l) => l.id == lineId)
      );
      this.invoice.invoiceLines.splice(indexToRemove, 1);
    },

    async openEditPostingModal(posting) {
      let result = await this.$refs.postingEditModal.open(posting);
      if (!result) return;

      await this.updatePosting({
        posting: posting,
        reconciliationId: this.reconciliationId,
      });
    },
    async initReconciliation() {
      try {
        await this.fetchInvoice({
          id: this.reconciliationId,
          include: ["invoiceLines"],
        });
        await this.fetchReconciliation({ id: this.reconciliationId });
        if (this.locationScope) {
          await this.fetchInfoCards({
            params: { locationId: this.locationScope },
          });
        }
      } finally {
        this.isLoading = false;
      }
    },
    async reopenReconciliation() {
      if (
        !(await this.$refs.confirmModal.confirm(
          "Du er ved at genåbne en afstemt faktura. Ønsker du at fortsætte?"
        ))
      )
        return;
      this.submitting = true;
      await this.reopenReconciliationAction(this.reconciliation.id);
      await this.initReconciliation();

      this.submitting = false;
    },
    async updateInvoiceNeedsConfirmation(e) {
      this.updateInvoiceNeedsConfirmationAction({
        id: this.invoice.id,
        value: e,
      });
    },
    async openinfoCardCreateModal() {
      const result = await this.$refs.infoCardCreateModal.openCreate(
        this.locations.find((l) => l.id == this.locationScope)
      );
      if (result) {
        await this.fetchInfoCards({
          params: { locationId: this.locationScope },
        });
      }
    },
    handleSaveClicked() {
      this.$emit("soft-save-clicked");
    },
    async handleSaveAndClose() {
      const someLinesAreNotPosted = this.invoice.invoiceLines.some(
        (l) =>
          !this.reconciliation.postings.some(
            (p) => p.postingSubjectLineId == l.id
          )
      );
      if (
        someLinesAreNotPosted &&
        !(await this.$refs.confirmModal.confirm(
          this.T(
            "Web.Generic.Invoices.Reconciliations.ReconciliationMissingPostingConfirm"
          )
        ))
      )
        return;

      try {
        this.submitting = true;
        const service = new ReconciliationService();
        await service.confirmReconciliation(this.reconciliationId);
        this.$emit("saved-and-closed");
      } finally {
        this.submitting = false;
      }
    },
    async refreshSuggestions() {
      try {
        this.isUpdatingSystemSuggestions = true;
        await this.refreshReconciliation(this.reconciliationId);
      } finally {
        this.isUpdatingSystemSuggestions = false;
      }
    },
    getDocumentTypeDisplayText(type) {
      return this.T(`Web.Generic.Invoices.DocumentTypes.${type}`);
    },
    async setLocation(locationId) {
      let locationIdToSet =
        locationId != null ? locationId : this.locationScope;
      if (locationIdToSet == null) locationIdToSet = this.locations[0].id;

      const service = new ReconciliationService();
      await service.setLocationScope(this.reconciliationId, locationIdToSet);
      this.reconciliation.locationScopeId = locationIdToSet;
      this.reconciliation.locationConfirmed = true;
      await this.fetchInfoCards({
        params: { locationId: locationIdToSet },
      });
      await this.refreshSuggestions();
    },
    getLinePosting(lineId) {
      return this.reconciliation.postings.find(
        (p) => p.postingSubjectLineId == lineId
      );
    },
    getLinePostingCategory(lineId) {
      let posting = this.getLinePosting(lineId);
      return posting ? posting.postingCategory : null;
    },
    getLinePostingCategoryText(lineId) {
      const category = this.getLinePostingCategory(lineId);
      if (category == null) return "";
      return this.postingTypeOptions.find((c) => c.value == category)?.text;
    },
    getLinePostingInfocard(lineId) {
      let posting = this.getLinePosting(lineId);
      return posting ? posting.postingDestinationInfoCardId : null;
    },
    getLinePostingInfocardText(lineId) {
      const infocard = this.getLinePostingInfocard(lineId);
      if (infocard == null) return "";
      return this.infoCards.find((i) => i.id == infocard).name;
    },
    getLinePostingUnit(lineId) {
      let posting = this.getLinePosting(lineId);
      return posting ? posting.postingUnit : null;
    },
    getLinePostingSecondartUnit(lineId) {
      let posting = this.getLinePosting(lineId);
      return posting ? posting.postingSecondaryUnit : null;
    },
    getLinePostingUnitText(lineId) {
      const unit = this.getLinePostingUnit(lineId);
      if (unit == null) return "";
      return this.unitTypeOptions.find((u) => u.value == unit)?.text;
    },
    getLinePostingSecondaryUnitText(lineId) {
      const unit = this.getLinePostingSecondartUnit(lineId);
      if (unit == null) return "";
      return this.unitTypeOptions.find((u) => u.value == unit).text;
    },
    linePostingIsSystemGenerated(lineId) {
      let posting = this.getLinePosting(lineId);
      return posting ? posting.isSystemGenerated : false;
    },
    lineHasPosting(lineId) {
      let posting = this.getLinePosting(lineId);
      return posting ? true : false;
    },
    lineHasSecondaryPosting(lineId) {
      let posting = this.getLinePosting(lineId);
      return posting ? posting.postingSecondaryUnit != "Unknown" : false;
    },
    lineHasEmptyPosting(lineId) {
      let posting = this.getLinePosting(lineId);
      return posting ? posting.type == "EmptyPosting" : false;
    },
    rowClass(item, type) {
      if (this.selectedLines.includes(item.id)) return "table-warning";
      if (this.lineHasPosting(item.id)) {
        return "table-primary";
        let linePosting = this.getLinePosting(item.id);
        if (linePosting.type == "EmptyPosting") return "tabel-text-row";
        if (linePosting.postingCategory == "Rent") return "tabel-rent-row";
        if (linePosting.postingCategory == "WasteCollection")
          return "tabel-waste-collection-row";
        if (linePosting.postingCategory == "Charge") return "tabel-charge-row";
        if (linePosting.postingCategory == "Income") return "tabel-income-row";
        return "tabel--row";
      }
      return;
    },
    async handleRemovePosting(lineId) {
      if (
        !(await this.$refs.confirmModal.confirm(
          this.T(
            "Web.Generic.Invoices.Reconciliations.ComfirmDeletePostingText"
          )
        ))
      )
        return;
      if (this.selectedLines.length > 0) {
        this.selectedLines.forEach(async (l) => {
          let linePosting = this.getLinePosting(l);
          if (linePosting != null) {
            await this.deletePosting({
              postingId: linePosting.id,
              reconciliationId: this.reconciliationId,
            });
          }
        });
        this.selectedLines = [];
      } else {
        let posting = this.getLinePosting(lineId);
        if (posting == null) return;
        await this.deletePosting({
          postingId: posting.id,
          reconciliationId: this.reconciliationId,
        });
      }
    },
    setLinePostingCategory(lineId, category) {
      let existingPosting = this.getLinePosting(lineId);
      if (existingPosting) {
        // Handle update
      } else {
      }
    },
    async setLinePostingInfocard(lineId, infocardId) {
      let existingPosting = this.getLinePosting(lineId);
      if (existingPosting) {
        // Handle update
      } else {
        const postingDraftResult = await this.getInfoCardPostingDraft({
          reconciliationId: this.reconciliationId,
          invoiceLineId: lineId,
          infoCardId: infocardId,
        });
        this.updateLinePostingSelects(lineId, postingDraftResult);
      }
    },
    updatePostingUnitForLine(lineId, unit) {
      if (this.selectedLines.length > 0) {
        this.selectedLines.forEach((itemId) => {
          let postingUnitSelect = this.$refs[`${itemId}_postingUnit`];
          postingUnitSelect.$el.value = unit;
          this.getPostingDraftForLine(itemId);
        });
      } else {
        this.getPostingDraftForLine(lineId);
      }
    },
    updatePostingCategoryForLine(lineId, category) {
      if (this.selectedLines.length > 0) {
        this.selectedLines.forEach((itemId) => {
          let postingCategorySelect = this.$refs[`${itemId}_postingCategory`];
          postingCategorySelect.$el.value = category;
          this.getPostingDraftForLine(itemId);
        });
      } else {
        this.getPostingDraftForLine(lineId);
      }
    },
    updatePostingInfocardForLine(lineId, infocard) {
      if (this.selectedLines.length > 0) {
        this.selectedLines.forEach((itemId) => {
          let postingInfocardSelect = this.$refs[`${itemId}_postingInfocard`];
          postingInfocardSelect.$el.value = infocard;
          this.getPostingDraftForLine(itemId);
        });
      } else {
        this.getPostingDraftForLine(lineId);
      }
    },

    updateLinePostingSelects(lineId, postingDraftResult) {
      if (postingDraftResult.postingDraft.postingUnit != "Unknown") {
        let postingUnitSelect = this.$refs[`${lineId}_postingUnit`];
        if (postingUnitSelect.values == null) {
          this.$refs[`${lineId}_postingUnit`].value =
            postingDraftResult.postingDraft.postingUnit;
        }
      }
    },
    async editConfirmWasteCollection(lineId) {
      let existingPosting = this.getLinePosting(lineId);
      if (existingPosting) {
        let confirmResult = await this.$refs.confirmWasteCollectionModal.open(
          existingPosting
        );
        if (confirmResult.wasteCollectioCount == 1) {
          existingPosting.postingSecondaryQuantity =
            confirmResult.tonsWasteCollected;
          existingPosting.postingSecondaryUnit = "Tonnage";
          existingPosting.postingQuantity = 1;
          existingPosting.postingUnit = "Pcs";
        } else {
          existingPosting.postingSecondaryQuantity =
            confirmResult.tonsWasteCollected;
          existingPosting.postingSecondaryUnit = "Tonnage";
          existingPosting.postingQuantity = confirmResult.wasteCollectioCount;
          existingPosting.postingUnit = "Pcs";
        }

        await this.updatePosting({
          posting: existingPosting,
          reconciliationId: this.reconciliationId,
        });
      }
    },
    async getPostingDraftForLine(lineId) {
      let existingPosting = this.getLinePosting(lineId);
      let postingUnitSelect = this.$refs[`${lineId}_postingUnit`];
      let postingCategorySelect = this.$refs[`${lineId}_postingCategory`];
      let postingInfocardSelect = this.$refs[`${lineId}_postingInfocard`];
      if (existingPosting) {
        existingPosting.postingCategory = postingCategorySelect.$el.value;
        existingPosting.postingDestinationInfoCardId =
          postingInfocardSelect.$el.value;
        existingPosting.postingUnit = postingUnitSelect.$el.value;
        existingPosting.isSystemGenerated = false;
        /*
        if (
          existingPosting.postingCategory == "WasteCollection" &&
          existingPosting.postingQuantity != 1
        ) {
          let confirmResult = await this.$refs.confirmWasteCollectionModal.open(
            existingPosting
          );
          if (confirmResult.wasteCollectioCount == 1) {
            existingPosting.postingSecondaryQuantity =
              confirmResult.tonsWasteCollected;
            existingPosting.postingSecondaryUnit = "Tonnage";
            existingPosting.postingQuantity = 1;
            existingPosting.postingUnit = "Pcs";
          } else {
            existingPosting.postingSecondaryQuantity =
              confirmResult.tonsWasteCollected;
            existingPosting.postingSecondaryUnit = "Tonnage";
            existingPosting.postingQuantity = confirmResult.wasteCollectioCount;
            existingPosting.postingUnit = "Pcs";
          }
        }
        */

        await this.updatePosting({
          posting: existingPosting,
          reconciliationId: this.reconciliationId,
        });

        // Update
      } else {
        if (postingInfocardSelect.$el.value == "") return;
        const postingDraftResult = await this.getInfoCardPostingDraft({
          reconciliationId: this.reconciliationId,
          invoiceLineId: lineId,
          infoCardId: postingInfocardSelect.$el.value,
        });
        if (
          postingDraftResult.postingDraft.postingUnit != "Unknown" &&
          postingUnitSelect.$el.value == ""
        ) {
          postingUnitSelect.$el.value =
            postingDraftResult.postingDraft.postingUnit;
        }
        if (
          postingDraftResult.postingDraft.postingCategory != "Unknown" &&
          postingCategorySelect.$el.value == ""
        ) {
          postingCategorySelect.$el.value =
            postingDraftResult.postingDraft.postingCategory;
        }

        // Create posting if all data i present
        if (
          postingUnitSelect.$el.value != "" &&
          postingCategorySelect.$el.value != "" &&
          postingInfocardSelect.$el.value != ""
        ) {
          let posting = postingDraftResult.postingDraft;
          posting.postingCategory = postingCategorySelect.$el.value;
          posting.postingDestinationInfoCardId =
            postingInfocardSelect.$el.value;
          posting.postingUnit = postingUnitSelect.$el.value;

          // We need to ask to validate wastecollection posting
          /*
          if (
            posting.postingCategory == "WasteCollection" &&
            posting.postingQuantity > 1
          ) {
            let confirmResult =
              await this.$refs.confirmWasteCollectionModal.open(posting);
            if (confirmResult.wasteCollectioCount == 1) {
              posting.postingSecondaryQuantity =
                confirmResult.tonsWasteCollected;
              posting.postingSecondaryUnit = "Tonnage";
              posting.postingQuantity = 1;
              posting.postingUnit = "Pcs";
            } else {
              posting.postingSecondaryQuantity =
                confirmResult.tonsWasteCollected;
              posting.postingSecondaryUnit = "Tonnage";
              posting.postingQuantity = confirmResult.wasteCollectioCount;
              posting.postingUnit = "Pcs";
            }
          }
          */
          await this.createInfoCardPosting({
            reconciliationId: this.reconciliationId,
            posting: posting,
          });
          this.selectedLines = [];
        }
      }
    },
  },
};
</script>
<style lang="scss">
.tabel-text-row {
  background: #e4ece2;
}
.tabel-rent-row {
  background: #537e92;
}
.tabel--row {
  background: #e4ece2;
}
.tabel-income-row {
  background: #bdd1c7;
}
.tabel-charge-row {
  background: #719aa1;
}
ul.dropdown-menu.show {
  z-index: 999;
}
</style>
<style lang="scss" scoped>
.dark-btn {
  background-color: #000 !important;
  color: #fff;
}
.action-head {
  width: 100%;
  background-color: #f8f8f8;
  padding: 10px 15px;

  display: flex;
  justify-content: space-between;

  > div {
  }
}
.invoice-head {
  display: flex;
  margin: 15px 25px;
  justify-content: space-between;

  div {
    &:last-child {
      text-align: end;
    }
  }

  h1.document-title {
    font-size: 50px;
    color: #e6e3ed;
  }
}
.table-overlay {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
}

.overlay-content {
  text-align: center;
  color: #5e5873;
}

.overlay-content p {
  font-size: 25px;
}

.table-con {
  position: relative;
}
::v-deep th {
  position: sticky;
  top: -1px;
  background-color: #f8f8f8;
  z-index: 99;
}
</style>